const AboutRenIllu = () => (
  <svg
    width="944"
    height="121"
    viewBox="0 0 944 121"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M81.7617 96.9961H362.298"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M632.465 95.2505H895.536"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M157.953 25.749V97.1052"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M176.617 25.749V97.1052"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M153.367 25.3125H181.639"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M153.367 39.8242H181.639"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M243.098 25.749V97.1052"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M261.762 25.749V97.1052"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M238.512 25.3125H266.784"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M238.512 39.8242H266.784"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M241.022 25.6399C234.364 30.5497 217.772 34.4776 198.014 34.4776C177.492 34.4776 160.136 30.7679 153.914 25.5308"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M241.023 40.0422C236.438 45.934 219.737 49.8619 198.233 49.8619C177.056 49.8619 159.263 45.6067 154.242 39.9331"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M264.601 25.64C258.707 31.5318 242.66 34.4777 221.92 34.4777C201.289 34.4777 183.715 30.5499 177.82 25.2036"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M264.599 40.042C258.268 45.2791 242.331 48.5524 221.481 48.5524C200.851 48.5524 185.132 45.2791 178.691 40.042"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M154.569 25.0947C151.403 46.1524 128.807 65.5735 106.539 72.7746C96.7149 75.9387 91.5844 76.9207 82.9609 77.3571"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M156.315 40.042C148.346 53.244 137.54 64.482 110.796 73.7561C101.081 77.1385 90.711 78.7751 82.8516 78.7751"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M179.784 25.0947C175.527 44.1885 153.149 61.973 119.965 73.3201C107.303 77.6844 93.9857 79.9757 82.8516 79.9757"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M179.784 40.042C172.47 53.1349 150.311 66.6642 125.532 75.1745C110.578 80.3026 95.0773 81.5028 82.8516 81.2845"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M308.811 51.7166C304.444 54.4442 299.423 56.0809 294.074 56.0809C278.465 56.0809 265.584 42.6607 264.711 25.749"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M309.139 55.0991C304.772 57.8267 299.205 59.5725 289.927 59.5725C277.81 59.4634 267.44 51.4985 262.965 40.1514"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M309.135 59.6813C305.533 62.409 297.128 64.7003 290.251 64.7003C268.856 64.7003 245.824 47.8978 239.602 25.5308"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M309.135 64.9185C271.694 80.6299 247.788 53.8986 241.566 40.042"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M309.137 50.2983V69.9377H345.923V51.1712"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M327.039 51.062L326.93 69.8285"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M318.305 70.5923V97.1054"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M335.773 70.5923V97.1054"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M298.548 22.6943L297.348 26.5131"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M290.141 23.6763L291.996 27.1677"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M284.793 30.2227L288.614 31.4228"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M285.773 38.733L289.267 36.769"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M292.434 43.9701L293.525 40.1514"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M300.84 42.9885L298.875 39.4971"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M306.078 36.4419L302.258 35.2417"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M305.204 27.9316L301.602 29.8956"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M300.403 33.6049C300.403 36.2235 298.22 38.4056 295.6 38.4056C292.98 38.4056 290.797 36.2235 290.797 33.6049C290.797 30.9863 292.98 28.8042 295.6 28.8042C298.22 28.8042 300.403 30.9863 300.403 33.6049Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M0.546875 63.2819L32.0936 29.6769C32.4211 29.3496 32.8577 29.1313 33.2943 29.1313C33.731 29.1313 34.1676 29.3496 34.4951 29.6769L63.6403 62.5182L59.274 62.6273L33.1852 33.1683L5.13152 63.1728L0.546875 63.2819Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.69531 62.8458L5.13194 97.1055H59.0561V62.3003"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M40.8277 49.9707H34.7148V59.2448H40.8277V49.9707Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M37.7695 50.189V59.1358"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M35.0391 53.0259H40.6061"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M37.7695 53.3535V58.918"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M40.8277 65.5732H34.7148V74.8474H40.8277V65.5732Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M37.7695 65.6826V74.6294"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M35.0391 68.6284H40.6061"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M51.7418 65.5732H45.6289V74.8474H51.7418V65.5732Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M48.6875 65.6826V74.6294"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M45.8477 68.6284H51.4147"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.7418 65.5732H14.6289V74.8474H20.7418V65.5732Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.6836 65.6826V74.6294"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.8477 68.6284H20.4147"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M40.8277 81.394H34.7148V90.6682H40.8277V81.394Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M37.7695 81.6123V90.5591"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M35.0391 84.4487H40.6061"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.7418 81.394H14.6289V90.6682H20.7418V81.394Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.6836 81.6123V90.5591"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.8477 84.4487H20.4147"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M45.3008 96.7779V81.2847H54.6884V96.7779"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M82.8529 75.7202H71.7188V96.9961H82.8529V75.7202Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M81.3238 77.4658H73.2461V89.2494H81.3238V77.4658Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M78.2693 83.4668H76.1953V86.6309H78.2693V83.4668Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M75.9766 87.8311H78.5964"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M73.3555 91.8682H81.1057"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M71.7162 84.9946H58.6172"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M346.144 50.9531H336.647H327.259H317.762H308.266"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M365.573 9.27393H360.442H355.312H350.181H345.16"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M349.419 44.0791H340.686H331.844H323.112H314.379"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M362.297 16.2568H356.512H350.617H344.832H338.938"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M359.135 23.2397H352.586H345.927H339.378H332.828"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M355.86 30.1133H348.546H341.233H334.028H326.715"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M352.585 37.0962H344.616H336.538H328.57H320.492"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M308.266 50.9529L345.052 9.27393"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M317.762 50.9529L350.182 9.27393"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M327.258 50.9529L355.311 9.27393"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M336.648 50.9529L360.445 9.27393"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M346.145 50.9529L365.575 9.27393"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M402.358 93.1776L398.428 92.7411C397.992 92.7411 397.773 92.4138 397.773 92.0865C397.773 91.7592 398.101 91.5409 398.538 91.5409L402.031 91.7592L402.14 90.9954L397.228 90.4499C396.791 90.4499 396.573 90.1225 396.573 89.6861C396.573 89.3588 396.9 89.0315 397.337 89.0315L402.249 89.3588L402.358 88.595L396.573 88.0495C396.136 88.0495 395.809 87.7222 395.809 87.2858C395.809 86.9584 396.245 86.6311 396.682 86.6311L402.467 87.0675L402.576 86.3038L397.773 85.7583C397.337 85.7583 397.009 85.4309 397.009 84.9945C397.009 84.6672 397.446 84.3399 397.883 84.3399L404.65 84.8854H404.76C405.414 84.8854 405.087 84.2307 405.087 84.2307C405.087 84.2307 403.995 83.9034 402.576 83.467C401.157 83.0306 401.703 82.1577 401.703 82.1577L404.541 82.3759L407.27 83.0306L410.436 85.54C410.763 85.54 410.982 85.6491 411.527 85.6491C412.073 85.6491 412.728 85.54 413.383 85.3218C414.365 85.1036 417.204 83.3579 417.531 83.9034C420.151 87.3949 415.785 93.614 414.038 93.614C412.946 93.614 413.274 93.5049 412.51 93.2867C411.964 93.0684 411.418 92.8502 410.872 92.7411C410.436 92.7411 410.217 92.632 409.781 92.632L406.506 93.3958L402.358 93.1776Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M409.016 61.3184C412.618 61.6457 417.202 63.2823 419.386 65.9009C421.896 68.9559 422.988 70.4834 422.988 70.4834C422.988 70.4834 427.463 79.7575 427.463 81.5032C427.463 83.2489 429.319 91.3229 426.808 93.7232C424.407 96.1236 415.238 93.3959 415.238 93.3959"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M397.228 48.6616L396.355 49.0981C396.028 50.6256 396.028 52.2622 396.355 53.8988L397.01 54.4443V55.099C397.228 57.4993 398.975 60.8816 401.158 62.4091C402.25 62.8456 403.45 63.0638 404.651 63.0638C407.271 62.5182 410.218 60.0088 411.31 57.8266L411.637 57.2811L412.51 57.0629C413.384 55.6445 413.929 54.2261 414.257 52.5895L413.602 51.8257"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M412.401 34.914C415.021 36.987 416.331 40.5875 415.676 44.1881L414.912 47.7886C414.803 48.225 414.257 48.5524 413.711 48.4433L398.101 45.3883C397.556 45.2791 397.228 44.8427 397.337 44.2972L397.992 40.6966C398.756 37.0961 401.267 34.2593 404.542 33.2773"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M410 37.2054L409.563 36.6599L410.327 32.6229C410.437 32.1865 410.109 31.6409 409.563 31.5318L408.581 31.3136C408.035 31.2045 407.489 31.5318 407.38 31.9683L406.616 36.0052L406.07 36.3326"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M403.23 40.4788L404.54 33.9323L404.65 33.2777C404.65 33.0595 404.868 32.9504 405.086 32.9504L407.378 32.623"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M410.219 33.1685L412.184 34.3686C412.402 34.4777 412.402 34.696 412.402 34.8051L412.293 35.4597L410.983 42.0061"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M416.658 49.5343L416.767 49.3161L417.204 46.9157C417.313 46.4793 416.985 45.9338 416.439 45.8247L415.457 45.6064"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M395.152 45.3886V45.1704L395.589 42.77C395.698 42.3336 396.244 42.0063 396.79 42.1154L397.772 42.3336"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M395.262 45.1704C395.262 45.2795 395.262 45.2795 395.262 45.3886C395.043 47.8981 399.519 49.862 405.304 51.0622C411.089 52.1533 416.002 52.0442 416.766 49.6438C416.766 49.5347 416.766 49.5347 416.875 49.4256"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M410.11 93.3957L407.599 98.8511C407.272 99.5058 406.508 100.051 405.962 100.051H387.514C386.859 100.051 386.641 99.5058 386.859 98.8511L396.793 77.5752C397.12 76.9205 397.884 76.375 398.43 76.375H399.74"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M414.582 76.375H416.874C417.529 76.375 417.748 76.9205 417.529 77.5752L414.582 83.9034"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M411.963 74.9568H410.217C410.763 73.6475 410.217 72.6655 409.016 72.6655C407.815 72.6655 406.178 73.6475 405.632 74.9568H403.886C402.903 74.9568 401.812 75.7205 401.375 76.7025H412.837C413.382 75.7205 412.946 74.9568 411.963 74.9568Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M399.301 60.6635C399.301 60.6635 396.245 59.4633 392.533 60.0089C392.752 60.0089 388.604 60.8817 387.621 61.8637"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M367.974 49.0981C367.865 49.3164 367.756 49.4255 367.646 49.6437C366.118 51.9349 364.699 53.2442 364.699 53.2442C364.699 53.2442 368.083 58.8087 372.34 60.0089C374.851 60.6635 376.816 59.7907 378.016 58.8087"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M368.846 45.3887C368.301 46.9162 367.973 48.0072 367.864 48.3346C367.755 48.771 368.192 50.1894 369.174 50.2985C369.283 50.2985 369.611 50.4076 369.72 50.4076"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M370.483 46.043C370.483 46.1521 370.592 46.2612 370.592 46.3703V46.4794C370.483 46.4794 370.374 46.4794 370.265 46.4794C368.955 46.9158 368.737 48.7707 369.61 50.0799C370.483 51.3892 371.138 51.6074 371.793 51.7165C371.902 52.153 371.902 52.3712 371.902 52.3712C372.011 52.9167 372.448 54.0078 372.994 54.7716C373.54 55.6444 376.378 57.281 377.251 58.1539C378.233 59.0267 378.998 59.5723 380.417 59.245C381.726 58.9176 383.364 56.6264 383.582 56.2991C383.91 55.9717 384.783 55.9717 385.001 55.5353C385.11 55.4262 385.11 55.3171 385.11 55.0989"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M359.023 86.0854C359.46 88.3767 360.879 94.1594 362.08 95.9051C363.608 98.0872 365.9 98.851 369.612 99.1783C373.214 99.3965 381.183 99.1783 383.912 99.3965C384.676 99.3965 385.44 99.3965 386.095 99.3965"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M389.804 92.6318C389.367 92.9591 389.04 93.1773 388.712 93.5046C387.73 94.2684 385.438 94.2684 382.709 94.1593C379.87 94.0502 374.522 92.85 372.448 92.6318C370.374 92.4136 369.282 92.1954 369.064 91.868C368.955 91.6498 368.409 88.813 367.754 85.6489"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M376.488 48.771L384.02 54.6628C384.784 55.2083 385.767 55.3174 386.531 54.9901L391.989 52.6989C392.862 52.2624 393.08 51.0623 392.425 50.4076L388.277 46.5889"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M368.629 45.2792C368.629 45.2792 374.96 48.2251 377.471 49.3162C380.09 50.4072 387.186 48.3342 388.059 46.6976C388.059 46.6976 387.95 41.024 386.531 38.7328C384.893 36.1142 382.929 33.4956 378.126 33.4956C373.323 33.4956 370.703 35.6778 369.611 39.2783C368.847 41.7878 368.629 45.2792 368.629 45.2792Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M383.91 56.4082C383.91 56.4082 387.185 61.9727 387.731 63.282C388.276 64.7004 390.241 68.8464 390.678 72.3379C391.333 77.2477 393.298 81.1756 393.079 83.3577"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M368.084 85.4308C368.848 84.9944 369.175 84.558 369.175 84.558C369.175 84.558 366.01 78.6662 366.119 76.484C366.228 74.3019 365.573 67.4281 364.918 65.7915"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M367.536 49.8618C367.536 49.8618 362.843 52.1531 361.751 53.2441C360.66 54.4443 355.638 57.6084 355.202 58.2631C354.765 58.9177 352.691 60.9908 352.582 63.282C352.473 65.5733 352.036 70.7013 354.219 74.5201C356.402 78.3388 357.276 82.2667 357.494 83.6851C357.712 85.1035 358.258 85.9763 358.258 85.9763C358.258 85.9763 359.022 86.3037 360.987 86.5219C362.952 86.7401 367.427 85.9763 368.191 85.5399"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M429.32 41.6788L415.784 44.0792M458.465 62.0819C459.775 65.5733 461.412 68.1919 464.032 69.0647L458.465 62.0819ZM470.036 62.9547L468.617 62.5183L468.289 61.7545L468.617 60.8817L467.525 61.209L467.089 60.227L467.416 59.3542H466.652L466.215 58.2631L466.434 57.6085L470.363 54.4444L472.437 52.8077L472.328 52.1531L471.564 51.4985L471.018 51.6076L468.617 53.4624L464.578 56.6265L470.036 62.9547ZM467.307 59.4633L470.473 57.0629L470.691 56.4083L469.818 55.4263L469.163 55.5354L467.307 59.4633ZM468.617 60.9908L471.564 58.8086L471.782 58.0449L470.909 57.0629L470.254 57.172L468.617 60.9908ZM470.909 59.2451L471.564 59.136L472.437 60.227L472.219 60.7726L470.8 62.3001L467.089 65.1369L463.596 67.319L464.141 68.9556L454.208 78.6662C453.116 79.6481 451.37 79.9755 449.842 79.539L439.472 76.0476L437.943 76.9205L437.288 88.4858L470.909 59.2451ZM464.251 62.6274V58.9177L462.722 56.4083L463.923 54.117L464.032 53.1351L463.159 52.3713L462.504 52.4804L460.43 56.081L459.775 60.8817L459.12 61.9728L458.683 61.8636L451.588 68.9556L450.606 69.0647C447.986 67.8645 440.891 64.5913 437.834 63.6094C437.398 63.5003 437.07 63.3911 436.524 63.282L430.63 62.4092C429.538 62.191 427.683 61.9728 427.683 60.5544V58.154L464.251 62.6274ZM422.989 55.7536C423.425 56.7356 424.08 57.9358 425.063 58.0449L431.394 58.154C432.704 58.154 433.468 57.3902 433.468 56.2992V51.2802L435.542 51.1711C435.651 51.1711 435.76 51.062 435.76 51.062C435.869 50.7347 435.869 49.9709 435.76 49.6436L433.25 45.934L422.989 55.7536ZM413.383 35.5688C418.732 30.5498 430.302 30.8772 433.031 40.6968C433.14 41.0241 433.359 41.2424 433.686 41.3515L434.996 41.5697C435.433 41.6788 435.76 41.7879 435.869 42.2243L436.088 43.3154C436.197 43.7518 435.869 43.97 435.433 43.97C429.32 44.952 423.316 45.934 417.203 46.9159L413.383 35.5688ZM414.365 36.6599C416.003 35.3506 418.186 34.4777 420.478 34.3686C425.499 34.1504 429.757 37.2054 430.63 41.3515L414.365 36.6599Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M428.666 61.9729C428.666 61.9729 426.265 63.173 422.553 62.6275C420.916 62.3002 417.75 61.3182 416.004 60.6636"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M395.589 47.2432C395.589 47.2432 394.388 47.8978 394.388 48.4433C394.388 48.9889 393.734 49.6435 393.734 50.2982C393.734 50.9528 394.388 52.153 393.734 52.6985C393.079 53.3532 391.878 53.8987 391.878 54.5534C391.878 55.208 391.223 55.208 391.223 55.7535C391.223 56.4082 390.022 56.4082 390.022 56.9537C390.022 57.4993 389.367 57.6084 390.022 58.1539C390.677 58.8085 389.367 60.5543 389.367 60.5543"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M416.877 48.771C416.877 48.771 418.296 49.6439 418.296 50.2985C418.296 50.9531 417.095 51.4987 417.641 52.1533C418.296 52.808 419.497 54.0081 418.296 55.2083C417.859 55.6448 418.296 58.2633 417.095 58.2633C415.895 58.2633 415.895 63.1732 415.895 63.1732"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M496.019 81.3939C490.561 81.3939 488.924 77.6842 487.723 74.9565C487.614 74.7383 487.505 74.411 487.396 74.1928C486.959 73.3199 486.304 71.5742 485.431 69.1738C484.558 66.7735 487.505 63.282 487.614 63.1729L487.723 63.0638L505.953 56.7356C507.918 56.081 509.228 54.3352 509.446 52.2622L509.664 49.5345L511.411 48.8799L512.502 52.6986C513.048 54.4444 513.157 56.1901 513.048 58.0449C512.502 63.8276 510.319 77.5751 501.805 80.3028C499.622 80.9574 497.657 81.3939 496.019 81.3939Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M485.974 5.34607L486.083 1.96374C485.864 1.3091 485.319 0.872669 484.555 0.654454C483.9 0.43624 483.135 0.654454 482.59 1.09088L473.966 8.8375C472.438 10.2559 471.674 12.3289 471.892 14.402L478.987 63.3912C479.097 64.3731 479.752 65.3551 480.625 65.9006C481.28 66.3371 481.935 66.5553 482.699 66.5553C484.445 66.5553 485.864 65.4642 486.301 63.9367C486.41 63.7185 486.41 63.5003 486.41 63.282L490.667 20.6211"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M536.188 23.8942L531.058 20.8392C528.766 19.4208 526.146 18.7661 523.526 18.8752C516.758 19.2026 500.93 19.9663 491.324 20.4027C486.631 20.621 483.465 20.8392 483.465 20.8392C481.391 20.9483 479.535 19.5299 479.208 17.4568C478.771 15.1656 480.19 13.0926 482.373 12.6561L527.128 3.16379C531.276 2.29093 535.533 3.382 538.808 6.10969C542.52 9.16469 551.798 17.0204 551.798 17.0204L553.654 18.4388"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M530.729 96.3415C523.852 96.3415 518.176 90.668 518.176 83.7942V83.576C518.176 77.1386 522.979 71.7924 529.419 71.1377L555.835 68.5192C559.219 68.1918 562.712 67.9736 566.096 67.9736L594.368 68.0827C597.643 68.0827 601.027 68.301 604.302 68.6283L628.644 71.1377C635.084 71.7924 639.887 77.1386 639.887 83.576V83.7942C639.887 90.668 634.211 96.3415 627.334 96.3415H557.145"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M566.098 71.0288C562.823 71.0288 559.44 71.247 556.165 71.5743L529.748 74.1929C524.836 74.6294 521.125 78.7754 521.125 83.6853V83.9035C521.125 89.1406 525.382 93.3958 530.622 93.3958H627.227C632.466 93.3958 636.724 89.1406 636.724 83.9035V83.6853C636.724 78.7754 633.012 74.7385 628.1 74.1929L603.758 71.6835C600.592 71.3561 597.317 71.1379 594.152 71.1379L566.098 71.0288Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M553.327 18.6574C554.964 16.257 557.803 14.7295 560.859 14.7295H592.187C593.606 14.7295 594.916 15.7115 595.244 17.1299L599.064 32.9504V66.119H550.707C547.542 66.119 544.922 63.5005 544.922 60.3363V60.1181C544.922 50.2985 546.341 40.4788 549.07 31.0956L551.908 21.2759C552.345 20.294 552.781 19.4211 553.327 18.6574Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M561.841 19.9663C559.548 19.9663 557.474 21.4938 556.819 23.7851L554.091 33.2774C552.235 39.7147 551.034 46.3703 550.488 53.0258C550.488 53.4622 550.597 54.0078 550.925 54.3351C551.362 54.7715 551.907 54.9898 552.453 54.8806C555.4 54.0078 557.256 53.244 559.003 52.4803C561.95 51.171 564.461 50.0799 570.901 49.2071C572.975 48.9889 574.503 47.134 574.721 44.8428L576.031 22.5849C576.031 21.9302 575.813 21.2756 575.376 20.8392C574.94 20.4027 574.285 20.0754 573.63 20.0754H561.841V19.9663Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M585.638 21.7119C585.201 21.7119 584.764 22.0392 584.764 22.5848L584.109 41.8968C584.109 42.3332 584.219 42.6605 584.546 42.9878C584.874 43.3152 585.419 43.5334 585.856 43.4243L593.06 41.6786C593.824 41.5694 594.261 40.9148 594.261 40.2602V35.8959C594.261 34.5866 594.152 33.2773 593.825 31.968L591.314 22.4757C591.205 22.0392 590.877 21.821 590.441 21.821H585.638V21.7119Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M483.684 4.25488C482.92 4.25488 482.374 4.69131 482.047 5.34596C481.828 5.78239 481.828 6.21881 482.047 6.65524C482.265 7.09167 482.592 7.41899 483.029 7.63721C483.902 7.96453 484.994 7.5281 485.321 6.65524C485.758 5.78238 485.321 4.69131 484.339 4.36399C484.23 4.25488 483.902 4.25488 483.684 4.25488Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M530.619 7.52832C529.418 7.52832 528.327 8.29207 527.89 9.38314C527.563 10.1469 527.563 10.9106 527.89 11.6744C528.218 12.4381 528.763 12.9837 529.527 13.311C531.056 13.9657 532.802 13.2019 533.457 11.7835C534.112 10.256 533.348 8.51029 531.929 7.85564C531.492 7.63743 531.056 7.52832 530.619 7.52832Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M482.701 61.5361C482.046 61.5361 481.5 62.0817 481.5 62.7363C481.5 63.391 482.046 63.9365 482.701 63.9365C483.356 63.9365 483.901 63.391 483.901 62.7363C483.901 62.0817 483.356 61.5361 482.701 61.5361Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M474.512 15.7114L481.061 59.3543"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M485.43 5.34619H501.258"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M484.336 7.52832H501.255"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M484.883 7.52832L489.358 11.238"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M501.695 8.61943V3.7096L526.365 3.16406"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M536.734 22.367L530.73 13.7476"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M533.352 11.7832L539.355 20.5118"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M547.103 41.3513L535.859 23.1303L540.553 19.5298L550.268 28.4766"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M580.398 14.6201V65.9005"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M598.953 32.8413H612.052L616.528 28.2588H639.342C639.342 28.2588 644.8 29.7863 644.8 34.9143V55.4265C644.8 55.4265 643.708 63.6096 636.067 63.6096H598.953"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M639.89 35.896H635.523V40.2603H639.89V35.896Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M632.245 35.896H627.879V40.2603H632.245V35.896Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M599.5 45.7158H644.255"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M621.332 32.623H644.801"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M539.464 91.5409C543.986 91.5409 547.651 87.8772 547.651 83.3579C547.651 78.8385 543.986 75.1748 539.464 75.1748C534.943 75.1748 531.277 78.8385 531.277 83.3579C531.277 87.8772 534.943 91.5409 539.464 91.5409Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M614.238 91.5409C618.759 91.5409 622.424 87.8772 622.424 83.3579C622.424 78.8385 618.759 75.1748 614.238 75.1748C609.716 75.1748 606.051 78.8385 606.051 83.3579C606.051 87.8772 609.716 91.5409 614.238 91.5409Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M628.428 81.721C629.935 81.721 631.157 80.4998 631.157 78.9933C631.157 77.4869 629.935 76.2656 628.428 76.2656C626.921 76.2656 625.699 77.4869 625.699 78.9933C625.699 80.4998 626.921 81.721 628.428 81.721Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M525.819 86.0853C527.326 86.0853 528.548 84.864 528.548 83.3576C528.548 81.8511 527.326 80.6299 525.819 80.6299C524.312 80.6299 523.09 81.8511 523.09 83.3576C523.09 84.864 524.312 86.0853 525.819 86.0853Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M628.428 91.5408C629.935 91.5408 631.157 90.3196 631.157 88.8131C631.157 87.3067 629.935 86.0854 628.428 86.0854C626.921 86.0854 625.699 87.3067 625.699 88.8131C625.699 90.3196 626.921 91.5408 628.428 91.5408Z"
      stroke="#333333"
      strokeMiterlimit="10"
    />
    <path
      d="M543.828 76.2656H610.415"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path d="M544.922 89.3589H608.234" stroke="#333333" strokeMiterlimit="10" />
    <path
      d="M628.973 53.3535V59.9"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M631.918 53.3535V59.9"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M637.703 53.3535V59.9"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M634.758 53.3535V59.9"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M484.883 68.6283L513.264 58.8086"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M493.617 60.9907V65.355"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M499.074 58.8086V63.1729"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M504.531 57.7178V62.0821"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M443.731 96.0141C445.66 96.0141 447.224 94.451 447.224 92.5227C447.224 90.5944 445.66 89.0312 443.731 89.0312C441.802 89.0312 440.238 90.5944 440.238 92.5227C440.238 94.451 441.802 96.0141 443.731 96.0141Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M444.277 96.1235H464.908C466.873 96.0144 468.401 94.4869 468.401 92.6321C468.401 90.6681 466.873 89.1406 464.908 89.1406H444.277"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M431.724 96.0141C433.653 96.0141 435.217 94.451 435.217 92.5227C435.217 90.5944 433.653 89.0312 431.724 89.0312C429.794 89.0312 428.23 90.5944 428.23 92.5227C428.23 94.451 429.794 96.0141 431.724 96.0141Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M443.295 89.0312H432.27"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M432.27 96.1235H443.731"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M466.328 95.9052C466.328 95.9052 470.149 95.9052 473.642 98.742C477.135 101.47 477.135 109.107 482.593 113.253C488.051 117.399 492.963 122.855 499.84 119.472C506.717 115.981 506.498 111.617 507.808 104.743C509.227 97.8691 518.178 96.0143 520.907 96.0143C523.636 96.0143 530.731 97.1053 532.915 96.0143C537.827 93.5048 537.718 96.0143 539.027 95.3596C540.447 94.705 550.052 93.2866 554.855 95.3596C555.729 95.687 557.257 96.8871 557.912 97.1053"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M491.434 62.082C491.434 62.082 493.617 53.3534 496.892 55.5356C500.166 57.7177 499.62 49.5347 501.804 48.4436C503.987 47.3525 509.445 52.8079 509.445 52.8079"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M574.61 33.1685C572.317 32.8411 570.025 32.4047 567.842 32.0774C567.733 32.0774 567.514 31.9683 567.623 31.8592L567.733 31.4227C567.733 31.3136 567.951 31.2045 568.06 31.2045L568.497 31.0954C568.606 31.0954 568.715 30.9863 568.715 30.8772C569.588 27.604 573.081 27.1676 575.265 28.3677M568.606 32.732L567.623 34.1504C567.623 34.2595 567.623 34.5868 567.623 34.696C567.623 34.696 567.623 34.696 567.733 34.8051L568.497 34.9142V36.769C568.497 37.2054 568.824 37.4236 569.261 37.4236H571.662C571.99 37.4236 572.208 36.9872 572.426 36.5508L568.606 32.732Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M570.027 38.0781C570.027 38.0781 567.843 41.3513 566.752 41.3513C565.66 41.3513 560.202 42.4424 559.111 43.5335L555.836 46.8067C555.836 46.8067 561.294 45.7156 563.477 45.7156C565.66 45.7156 566.752 46.8067 566.752 46.8067L566.643 49.8617"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M552.562 54.4442L558.02 46.8066"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M682.021 41.0239C681.475 40.2602 680.93 39.6055 680.93 39.6055C680.93 39.6055 683.877 36.1141 684.532 36.2232C684.532 36.2232 685.078 36.987 685.842 37.5325C686.169 37.7507 686.278 37.7507 686.715 38.078"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M667.613 26.0762C667.504 26.1853 667.504 26.2944 667.395 26.4035C666.412 27.931 665.43 28.8039 665.43 28.8039C665.43 28.8039 667.722 32.5135 670.56 33.2773C672.198 33.7137 673.507 33.1681 674.381 32.5135"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M668.156 23.5669C667.829 24.5489 667.61 25.3126 667.501 25.5308C667.392 25.8581 667.719 26.731 668.374 26.8401L668.702 26.9492"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M669.141 24.0034C669.141 24.1125 669.141 24.1125 669.25 24.2216C669.25 24.2216 669.25 24.2216 669.25 24.3307C669.141 24.3307 669.141 24.3307 669.032 24.3307C668.159 24.6581 668.05 25.8582 668.595 26.7311C669.141 27.604 669.578 27.8222 670.014 27.8222C670.124 28.1495 670.124 28.2586 670.124 28.2586C670.233 28.5859 670.451 29.3497 670.888 29.8952C671.324 30.4407 673.071 31.5318 673.726 32.1865C674.381 32.732 674.926 33.0593 675.8 32.9502C676.673 32.732 677.765 31.2045 677.874 30.9863C678.092 30.7681 678.638 30.7681 678.856 30.4407C678.856 30.3316 678.856 30.3316 678.856 30.2225"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M661.609 50.5166C661.937 52.0441 662.81 55.8629 663.683 56.9539C664.666 58.3723 666.194 58.9179 668.596 59.1361C670.997 59.2452 676.237 59.1361 678.092 59.2452C679.621 59.3543 680.712 59.1361 682.022 59.3543"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M682.022 54.8806C681.694 55.0988 681.476 55.2079 681.258 55.4261C680.603 55.9716 679.074 55.9717 677.219 55.8625C675.363 55.7534 671.87 54.9897 670.451 54.8806C669.032 54.7715 668.377 54.5533 668.268 54.335C668.268 54.2259 667.831 52.262 667.395 50.189"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M673.18 25.8583L678.092 29.7862C678.528 30.1135 679.183 30.2226 679.729 30.0044L683.331 28.4769C683.986 28.2587 684.096 27.3858 683.55 26.9494L680.821 24.4399"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M667.938 23.5669C667.938 23.5669 672.085 25.5308 673.832 26.1855C675.579 26.9492 680.272 25.5308 680.927 24.4397C680.927 24.4397 680.927 20.7301 679.945 19.0935C678.853 17.3478 677.543 15.6021 674.378 15.6021C671.212 15.6021 669.466 17.0204 668.702 19.4208C668.156 21.1665 667.938 23.5669 667.938 23.5669Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M682.02 29.0225C682.02 29.0225 682.784 31.9684 683.329 32.623C683.875 33.1685 685.513 35.0234 685.513 35.0234C685.513 35.0234 684.748 36.2235 684.421 36.3326"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M679.945 29.895C679.945 29.895 680.273 34.4775 680.6 35.4595C680.928 36.4415 681.255 39.1691 680.819 41.4604C680.382 43.7516 680.164 47.7886 680.054 49.207C679.945 50.6254 680.382 55.6443 680.382 55.6443"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M667.613 50.08C668.159 49.7526 668.268 49.5344 668.268 49.5344C668.268 49.5344 666.194 45.6066 666.194 44.1882C666.303 42.7698 665.866 38.1873 665.43 37.0962"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M667.284 26.5127C667.284 26.5127 664.118 28.0402 663.463 28.8039C662.699 29.5677 659.425 31.6407 659.097 32.0772C658.77 32.5136 657.46 33.932 657.351 35.3504C657.241 36.8779 657.023 40.2602 658.442 42.7697C659.861 45.2792 660.407 47.8977 660.625 48.7706C660.735 49.6434 661.171 50.2981 661.171 50.2981C661.171 50.2981 661.717 50.5163 663.027 50.6254C664.337 50.7345 667.284 50.2981 667.83 49.9708"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M673.615 94.9229C674.488 89.6857 675.253 84.5577 675.253 83.3575C675.143 80.7389 672.633 65.6821 672.633 65.6821"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M661.828 51.8257L660.518 57.281C660.518 57.281 658.99 60.9907 659.863 64.9186C660.191 66.5552 664.23 79.5389 664.557 80.8482C664.885 82.1575 665.758 86.3036 665.758 86.3036C665.758 86.3036 664.23 92.6318 663.902 93.5047C663.902 93.6138 663.793 94.0502 663.684 94.7049"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M682.348 95.2506C682.566 91.7592 683.003 84.3399 683.003 83.5761C683.003 82.7033 682.893 76.5933 682.893 76.5933"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M680.492 59.2451L682.02 66.0098"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M681.91 40.9152L700.358 30.2227L712.474 37.2055L694.027 47.898L681.91 40.9152Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M681.91 40.915V76.0476L694.027 83.0305V47.8979"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M712.475 37.2051V72.4467L694.027 83.0302"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M700.359 30.2226L718.807 19.6392L720.008 20.2938L701.56 30.8772"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M720.008 20.2935V55.426L712.367 59.7903"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M709.638 53.3531L704.508 46.9158C703.962 46.2612 702.761 46.8067 702.216 48.0069L697.085 59.2449C696.539 60.4451 697.085 61.2089 698.177 60.7724L708.438 55.9717C709.529 55.3171 710.184 53.8987 709.638 53.3531Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M703.196 49.971L704.506 49.3164L702.869 53.2443L704.943 51.3894L703.633 55.8628L704.179 55.4264L703.087 57.1721L702.651 55.7537L703.196 55.972L703.742 53.1352L701.777 54.8809L703.196 49.971Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M919.767 95.3594H927.299V89.1403C927.299 85.6489 924.788 84.9942 919.985 83.1394C914.527 80.9573 914.527 75.3928 914.745 70.3739C915.073 64.0456 917.256 60.336 917.256 56.6263C917.256 53.1349 914.418 50.4072 911.034 50.4072C907.541 50.4072 904.812 53.244 904.812 56.6263C904.812 60.336 906.995 64.0456 907.323 70.3739C907.541 75.3928 907.541 80.9573 902.083 83.1394C897.28 84.9942 894.77 85.758 894.77 89.1403V95.3594H923.26"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M939.741 64.0454L931.009 72.774L927.188 69.0643L923.477 72.774L931.009 80.3024L943.453 67.7551L939.741 64.0454Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M775.354 52.5896C775.136 51.2803 774.481 50.1892 773.498 49.4255C772.625 48.7708 772.297 47.4615 772.625 46.2614C773.389 43.5337 774.371 38.9512 772.843 37.3146C772.297 36.769 771.533 36.3326 770.878 36.2235C769.678 36.0053 768.804 34.696 768.804 33.2776C768.804 28.2586 766.403 24.2217 763.456 24.2217C760.508 24.2217 758.107 28.2586 758.107 33.2776C758.107 34.696 757.233 36.0053 756.033 36.2235C755.269 36.3326 754.614 36.769 754.068 37.3146C752.54 38.8421 753.522 43.4246 754.286 46.2614C754.614 47.4615 754.286 48.6617 753.413 49.4255C752.431 50.1892 751.776 51.2803 751.557 52.5896C750.902 56.081 755.924 63.5003 763.565 70.265C770.878 63.5003 775.9 56.081 775.354 52.5896Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M813.883 44.8428C813.337 44.079 813.009 43.0971 813.119 42.2242C813.119 42.006 813.119 41.7878 813.119 41.4605C813.119 38.7328 812.027 36.3324 810.28 34.5867C809.407 33.7138 808.971 32.4046 809.189 31.2044C809.189 30.8771 809.298 30.6588 809.189 30.3315C809.189 27.1674 806.569 24.5488 803.294 24.5488C800.02 24.5488 797.4 27.1674 797.4 30.4406C797.4 30.6588 797.4 30.9862 797.4 31.2044C797.509 32.4046 797.181 33.7138 796.308 34.5867C794.562 36.3324 793.47 38.7328 793.47 41.4605C793.47 41.6787 793.47 42.006 793.47 42.2242C793.579 43.2062 793.252 44.079 792.706 44.8428C791.178 47.0249 790.195 49.6435 790.195 52.4803C790.195 59.6814 795.981 65.4641 803.185 65.4641C810.39 65.4641 816.175 59.6814 816.175 52.4803C816.393 49.6435 815.411 47.0249 813.883 44.8428Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M850.452 39.4968C851.107 38.0784 851.434 36.5509 851.434 34.9143C851.434 29.1316 846.74 24.44 840.955 24.3309C835.061 24.2218 830.148 29.0225 830.148 34.9143C830.148 36.5509 830.476 37.9693 831.131 39.3877C832.441 42.1154 832.441 45.2795 831.131 48.0072C830.476 49.4256 830.148 50.9531 830.148 52.5897C830.148 58.3724 834.842 63.064 840.628 63.1731C846.522 63.2822 851.434 58.4815 851.434 52.5897C851.434 50.9531 851.107 49.5347 850.452 48.1163C849.251 45.4977 849.142 42.2245 850.452 39.4968Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M866.609 47.6797C866.609 60.8816 876.106 69.2829 876.106 69.2829C876.106 69.2829 885.603 60.8816 885.603 47.6797C885.603 34.4777 876.106 23.7852 876.106 23.7852C876.106 23.7852 866.609 34.4777 866.609 47.6797Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M875.996 41.7881V94.596"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M763.234 41.7881V94.596"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M803.078 31.6411V94.596"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M840.301 41.7881V94.596"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M755.375 54.5537L762.907 61.4275L768.037 57.7178"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M759.086 46.9161L762.906 51.0622L769.893 41.7881"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M871.082 44.1885L875.994 50.0803L880.47 44.2976"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M869.336 51.062L875.995 56.6265L882.217 51.1711"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M834.953 52.1533L840.302 56.6267L845.214 52.3715"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M835.824 47.3525L840.191 51.0622L844.339 47.4616"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M798.164 37.2056L802.858 46.6979L808.316 40.9152"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M795.656 51.062L802.861 61.6454L811.157 51.2802"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M807.008 50.4072L807.226 56.299L812.029 56.8446"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M792.379 54.7716L799.147 55.5353L800.457 50.6255"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default AboutRenIllu;
